import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { client } from "../../graphql/client"
import { REGISTER_PLAYER } from "../../graphql/queries"
import type { RootState } from "../store"

interface Currentplayer {
  playerId?: string
  name?: string
  admin?: boolean
}
/**
 * The initial state for the CurrentplayerSlice
 */
const initialState: Currentplayer = {}

/**
 * The CurrentplayerSlice in the redux state
 */
export const CurrentplayerSlice = createSlice({
  name: "currentplayer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /**
     * Add the playersData to the state when the fetch request is completed
     */
    builder.addCase(registerPlayer.fulfilled, (state, action) => {
      return (action.payload || {}) as Currentplayer
    })
  },
})

/**
 * Selector for getting the players data;
 */
export const selectCurrentplayer = () => (state: RootState) =>
  state["currentplayer"]

/**
 * Thunk function for fething the players data
 */
export const registerPlayer = createAsyncThunk(
  "currentplayer/register",
  async (name: string) => {
    if (name === "admin-mark-djamilla") {
      return {
        playerId: "647ba0d8025c4c9ab6473bd2",
        name,
        admin: true,
      } as Currentplayer
    } else if (name.length > 0) {
      const {
        data: { registerPlayer },
      } = await client.mutate({
        mutation: REGISTER_PLAYER,
        variables: { name },
      })

      return { playerId: registerPlayer, name, admin: false } as Currentplayer
    }
  },
)

export default CurrentplayerSlice.reducer
