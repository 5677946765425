import { gql } from "@apollo/client"
import { DocumentNode } from "graphql"

export const GET_PLAYERS: DocumentNode = gql`
  query Players($playerId: ID) {
    players(playerId: $playerId) {
      _id
      name
      admin
      score
      questionsAnswered
    }
  }
`

export const GET_QUESTIONS: DocumentNode = gql`
  query Questions {
    questions {
      round
      number
      question
      answerA
      answerB
      answerC
      answerD
      correctAnswers
      explanation
    }
  }
`

export const GET_QUIZSTATE: DocumentNode = gql`
  query QuizState {
    quizState
  }
`

export const REGISTER_PLAYER: DocumentNode = gql`
  mutation RegisterPlayer($name: String!) {
    registerPlayer(name: $name)
  }
`
export const SET_QUIZSTATE: DocumentNode = gql`
  mutation setQuizState($playerId: ID!, $quizState: String!) {
    setQuizState(playerId: $playerId, quizState: $quizState)
  }
`

export const REGISTER_ANSWER: DocumentNode = gql`
  mutation RegisterAnswer(
    $playerId: ID!
    $round: Int!
    $number: Int!
    $answer: String!
  ) {
    registerAnswer(
      playerId: $playerId
      round: $round
      number: $number
      answer: $answer
    )
  }
`
