import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import client from "../../graphql/client"
import { GET_QUESTIONS } from "../../graphql/queries"
import type { RootState } from "../store"

export interface Question {
  round: number
  number: number
  question: string
  answerA: string
  answerB: string
  answerC: string
  answerD: string
  correctAnswers?: [string]
  explanation?: string
}
/**
 * The initial state for the QuestionsSlice
 */
const initialState: Question[] = []

/**
 * Thunk function for fething the players data
 */
export const fetchQuestions = createAsyncThunk("questions/fetch", async () => {
  const { data } = await client.query({
    query: GET_QUESTIONS,
    variables: {},
    fetchPolicy: "no-cache",
  })
  return data.questions
})

/**
 * The QuestionsSlice in the redux state
 */
export const QuestionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQuestions.fulfilled, (state, action) => {
      const data = action.payload as Question[]
      if (data && data.length > 0) {
        return data
      }
      return []
    })
  },
})

/**
 * Selector for getting the questions data;
 */

export const selectQuestions = () => (state: RootState) => {
  return state["questions"]
}
export const selectRoundQuestions = (round: number) => (state: RootState) => {
  const returnvalue = round
    ? state["questions"].filter((question) => question.round === round)
    : undefined
  return returnvalue
}
export const selectQuestion =
  (round: number, number: number) => (state: RootState) => {
    const returnvalue = round
      ? state["questions"].filter(
          (question) => question.round === round && question.number === number,
        )?.[0]
      : undefined
    return returnvalue
  }

export default QuestionsSlice.reducer
