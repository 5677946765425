import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import client from "../../graphql/client"
import { GET_QUIZSTATE, SET_QUIZSTATE } from "../../graphql/queries"
import type { RootState } from "../store"

export enum Quizstate {
  INIT = "INIT",
  ETAPPE1 = "ETAPPE1",
  ETAPPE2 = "ETAPPE2",
  ETAPPE3 = "ETAPPE3",
  PUNTENTELLING = "PUNTENTELLING",
  RESULTAAT = "RESULTAAT",
}
/**
 * The initial state for the QuizstateSlice
 */
const initialState: string = Quizstate.INIT

/**
 * The QuizstateSlice in the redux state
 */
export const QuizstateSlice = createSlice({
  name: "quizstate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuizstate.fulfilled, (state, action) => {
        return action.payload as string
      })
      .addCase(changeQuizstate.fulfilled, (state, action) => {
        return action.payload as string
      })
  },
})

/**
 * Selector for getting the quizstate data;
 */
export const selectQuizstate = () => (state: RootState) => state["quizstate"]

/**
 * Thunk function for fething the quizstate data
 */
export const fetchQuizstate = createAsyncThunk("quizstate/fetch", async () => {
  const { data } = await client.query({
    query: GET_QUIZSTATE,
    variables: {},
    fetchPolicy: "no-cache",
  })
  return data.quizState
})
/**
 * Thunk function for chaning the quizstate data
 */
export const changeQuizstate = createAsyncThunk(
  "quizstate/change",
  async ({ playerId, quizState }: { playerId: string; quizState: string }) => {
    const { data } = await client.mutate({
      mutation: SET_QUIZSTATE,
      variables: { playerId, quizState },
    })
    return data.setQuizState
  },
)
export default QuizstateSlice.reducer
