import "./Home.scss"
import Page from "../components/Page/Page"
import Menu from "../components/Menu/Menu"

function Home() {
  return (
    <Page className="home-page">
      <div className="home-page-content">
        <h1>Trappen en happen</h1>
        <img src="./homeImage.png" />
        <p>Bedankt voor het meespelen!</p>
        <h1>Eindstand</h1>
        <img src="./stand.jpeg" />
      </div>
    </Page>
  )
}

export default Home
