import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import client from "../../graphql/client"
import { REGISTER_ANSWER } from "../../graphql/queries.js"
import type { RootState } from "../store"

/**
 * The initial state for the AnswersSlice
 */
const initialState: { [round: number]: { [number: number]: string } } = {}

/**
 * The AnswersSlice in the redux state
 */
export const AnswersSlice = createSlice({
  name: "answers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /**
     * Add the playersData to the state when the fetch request is completed
     */
    builder.addCase(registerAnswer.fulfilled, (state, action) => {
      if (action.payload) {
        const { round, number, answer } = action.payload
        if (!state[round]) {
          state[round] = {}
        }
        state[round][number] = answer
      }
      return state
    })
  },
})

/**
 * Selector for getting the stored answers;
 */
export const selectAnswers = () => (state: RootState) => {
  return state["answers"]
}
export const selectRoundAnswers = (round: number) => (state: RootState) => {
  const returnvalue = round ? state["answers"][round] : undefined
  return returnvalue
}
export const selectAnswer =
  (round: number, number: number) => (state: RootState) => {
    const returnvalue =
      round &&
      state["answers"][round] &&
      number &&
      state["answers"][round][number]
        ? state["answers"][round][number]
        : undefined
    return returnvalue
  }

/**
 * Thunk function for registering an answer
 */
export const registerAnswer = createAsyncThunk(
  "answers/register",
  async ({
    playerId,
    round,
    number,
    answer,
  }: {
    playerId: string
    round: number
    number: number
    answer: string
  }) => {
    const {
      data: { registerAnswer },
    } = await client.mutate({
      mutation: REGISTER_ANSWER,
      variables: { playerId, round, number, answer },
    })
    if (registerAnswer) {
      return {
        round,
        number,
        answer,
      }
    }
  },
)

export default AnswersSlice.reducer
