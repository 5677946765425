import { ApolloProvider } from "@apollo/client"
import "./App.css"
import ApplicationRouter from "./router/Router"
import client from "./graphql/client"

function App() {
  
  return (
    <ApolloProvider client={client}>
      <div className="app">
        <ApplicationRouter />
      </div>
    </ApolloProvider>
  )
}

export default App
