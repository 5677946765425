import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit"
import answersReducer from "./answers/AnswersSlice"
import playersReducder from "./players/PlayersSlice"
import currentPlayerReducer from "./currentplayer/CurrentplayersSlice"
import questionReducer from "./questions/QuestionsSlice"
import quizstateReducer from "./quizstate/QuizstateSlice"
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"

/**
 * Redux persist configuration
 */
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["currentplayer", "answers"],
}

/**
 * Combine the reducers
 */
const reducers = combineReducers({
  answers: answersReducer,
  questions: questionReducer,
  quizstate: quizstateReducer,
  players: playersReducder,
  currentplayer: currentPlayerReducer,
})

const reducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
