import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { client } from "../../graphql/client"
import { GET_PLAYERS } from "../../graphql/queries"
import type { RootState } from "../store"

interface Player {
  _id: string
  name: string
  admin: string
  score: number
  questionsAnswered: number
}
/**
 * The initial state for the PlayersSlice
 */
const initialState: Player[] = []

/**
 * The PlayersSlice in the redux state
 */
export const PlayersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /**
     * Add the playersData to the state when the fetch request is completed
     */
    builder.addCase(fetchPlayers.fulfilled, (state, action) => {
      const data = action.payload as Player[]
      if (data && data.length > 0) {
        return data
      }
      return []
    })
  },
})

/**
 * Selector for getting the players data;
 */
export const selectPlayers = () => (state: RootState) => state.players

/**
 * Thunk function for fething the players data
 */
export const fetchPlayers = createAsyncThunk(
  "players/fetch",
  async (playerId: string) => {
    const { data } = await client.query({
      query: GET_PLAYERS,
      variables: { playerId },
      fetchPolicy: "no-cache",
    })
    return data.players
  },
)

export default PlayersSlice.reducer
