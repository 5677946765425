import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { getMainDefinition } from "@apollo/client/utilities"
import { Kind, OperationTypeNode } from "graphql"
import { createClient as createWsClient } from "graphql-ws"

const httpLink = new HttpLink({
  uri:
    import.meta.env.MODESERVERHTTP ||
    "http://trappenenhappen-env.eba-3rvhappe.eu-central-1.elasticbeanstalk.com/graphql",
})

const wsLink = new GraphQLWsLink(
  createWsClient({
    url:
      import.meta.env.SERVERWS ||
      "ws://trappenenhappen-env.eba-3rvhappe.eu-central-1.elasticbeanstalk.com/graphql",
    connectionParams: () => ({}),
  }),
)

function isSubscription({ query }: { query: any }) {
  const definition = getMainDefinition(query)
  return (
    definition.kind === Kind.OPERATION_DEFINITION &&
    definition.operation === OperationTypeNode.SUBSCRIPTION
  )
}

export const client = new ApolloClient({
  link: split(isSubscription, wsLink, httpLink),
  cache: new InMemoryCache(),
})

export default client
