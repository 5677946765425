import React from "react"
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom"
import Home from "../Pages/Home"

const ApplicationRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  )
}

export default ApplicationRouter
